<script>
import CartButton from "./CartButton.vue";

export default {
    name: "ProductCard",
    components: {CartButton},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            hasSale: false,
            saleType: '%',
            saleAmount: 0,
        }
    },
    mounted() {
        this.hasSale = this.product.initial_price !== this.product.price && this.product.sale;
        if (this.product.hasOwnProperty('sale') && this.product.sale) {
            this.saleType = this.product.sale.type === 'PERCENT' ? '%' : '₾';
            this.saleAmount = this.product.sale.type === 'FIXED' ? this.product.initial_price - this.product.price : this.product.sale.amount;
        }
        if (this.saleAmount < 0) {
            this.saleAmount *= -1;
        }
    },
}
</script>

<template>
    <div class="product-card position-relative">
        <div class="position-absolute badge bg-danger sale-amount text-black fs-6" v-if="product.sale"
             onclick='location.href=product.view'>
            -{{ saleAmount }} {{ saleType }}
        </div>
        <a :href="product.view" class="d-flex justify-content-center w-100">
            <img :src="product.thumbnail" :alt="product.slug" class="mw-100"/>
        </a>
        <div class="card-body">
            <a :href="product.view" class="text-decoration-none">
                <p class="card-title text-truncate" :title="product.title">{{ product.title }}</p>
            </a>
            <small class="card-text line-clamp pt-1" :title="product.description">{{ product.description }}</small>
            <div class="d-flex justify-content-center justify-content-between align-items-center">
                <div class="price">
                    <span class="new-price">
                        {{ product.price }}₾
                    </span>
                    <br/>
                    <div class="old-price">
                        {{ product.sale ? product.initial_price + "₾" : "" }}
                    </div>
                </div>
                <div>
                    <cart-button :qty="product.qty" :product='product.slug'></cart-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.card-text {
    height: 63px;
}

.old-price {
    text-decoration: line-through;
    font-size: 14px;
}

.sale-amount {
    top: 5%;
    right: 5%;
}
</style>
