<script>
export default {
    name: "LiveGridPagination",
    props: ['current', 'last'],
    emits: ['changePage'],
}
</script>

<template>
    <nav aria-label="Page navigation example" v-if="last > 0">
        <ul class="pagination mb-0">
            <li class="page-item" :class="{disabled: current === 1}">
                <a class="page-link" href="#" @click.prevent="$emit('changePage', current - 1)">
                    <i class="bi bi-chevron-left"></i>
                </a>
            </li>
            <template v-for="n in last">
                <template v-if="n === 1">
                    <li class="page-item" :class="{active:  current === 1}">
                        <a class="page-link" href="#" @click.prevent="$emit('changePage', n)">{{ n }}</a>
                    </li>
                    <li class="page-item" v-if="current > 4">
                        <span class="page-link"> ...  </span>
                    </li>
                </template>
                <template v-else-if="n === last">
                    <li class="page-item" v-if="current <= last -  4">
                        <span class="page-link"> ...  </span>
                    </li>
                    <li class="page-item" :class="{active:  current === n}">
                        <a class="page-link" href="#" @click.prevent="$emit('changePage', n)">{{ n }}</a>
                    </li>
                </template>
                <template v-else-if="current - 4 < n-1 && current + 4 > n-1">
                    <li class="page-item" :class="{active:  current === n}">
                        <a class="page-link" href="#" @click.prevent="$emit('changePage', n)">{{ n }}</a>
                    </li>
                </template>
            </template>
            <li class="page-item" :class="{disabled: current === last}">
                <a class="page-link" href="#" @click.prevent="$emit('changePage', current + 1)">
                    <i class="bi bi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </nav>
</template>

<style scoped>

</style>
