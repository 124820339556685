import * as VueSelect from "vue-select";
import {createApp} from 'vue';
import {createI18n} from 'vue-i18n/dist/vue-i18n.cjs'
import {Toaster} from "@incuca/vue3-toaster";
import 'swiper/css/bundle';

import '../assets/fb.svg'
import '../assets/ig.svg'
import '../assets/phone.svg'
import ClickOutside from "./click-outside";
import {vMaska} from "maska";

const locale = document.querySelector('html').getAttribute('lang') ?? 'ka';

const i18n = createI18n({
    locale, globalInjection: true, missing(locale, slug) {
        axios.get(`/locale-create/${slug}`)
    }
})


const app = createApp({
    mounted() {
        !function () {
            "use strict";
            const t = document.querySelectorAll(".needs-validation");
            Array.prototype.slice.call(t).forEach(function (t) {
                t.addEventListener("submit", function (e) {
                    t.checkValidity() || (e.preventDefault(), e.stopPropagation()), t.classList.add("was-validated")
                }, !1)
            })
        }();
    },
});

app.directive('click-outside', ClickOutside);
app.directive("maska", vMaska);
app.component('VSelect', VueSelect)
app.component('FInput', require('./components/inputs/FInput').default);
app.component('FCheckbox', require('./components/inputs/FCheckbox').default);
app.component('FTranslation', require('./components/inputs/FTranslation').default);
app.component('FSelect', require('./components/inputs/FSelect').default);
app.component('FText', require('./components/inputs/FText').default);
app.component('FRangeInput', require('./components/inputs/FRangeInput.vue').default);
app.component('FEditor', require('./components/inputs/FEditor').default);
app.component('FNested', require('./components/inputs/FNested').default);
app.component('CartModal', require('./components/ecommerece/CartModal.vue').default);
app.component('CartModalButton', require('./components/ecommerece/CartModalButton.vue').default);
app.component('CartButton', require('./components/ecommerece/CartButton.vue').default);
app.component('CartQty', require('./components/ecommerece/CartQty.vue').default);
app.component('LiveGrid', require('./components/ecommerece/LiveGrid.vue').default);
app.component('LiveGridFilter', require('./components/ecommerece/LiveGridFilter.vue').default);
app.component('LiveGridPagination', require('./components/ecommerece/LiveGridPagination.vue').default);
app.component('ProductCard', require('./components/ecommerece/ProductCard.vue').default);
app.component('ProductImages', require('./components/ecommerece/ProductImages.vue').default);
app.component('LiveSearch', require('./components/LiveSearch.vue').default);
app.component('SiteMenu', require('./components/SiteMenu.vue').default);
app.component('UserAddress', require('./components/ecommerece/UserAddress.vue').default);
app.component('BuyButton', require('./components/ecommerece/BuyButton.vue').default);
app.component('SwiperWrapper', require('./components/swiper/SwiperWrapper.vue').default);
app.component('PasswordVisibility', require('./components/inputs/PasswordVisibility').default);

axios.get(`/locale/${locale}`).then(({data}) => {
    // noinspection JSUnresolvedReference
    i18n.global.setLocaleMessage(locale, data);
    app.use(Toaster.install, {maxToasts: 5}).use(i18n).mount("#app");
})

window.onload = function () {
    document.getElementsByClassName('site-loading').className = '';
    setTimeout(() => document.getElementsByClassName('site-loading')[0].remove(), 500);
};

