<script>
import {getQueryParam, gridList, updateQueryParam} from "../../utils";
import ProductCard from "./ProductCard.vue";
import LiveGridFilter from "./LiveGridFilter.vue";
import _ from "lodash";
import LiveGridPagination from "./LiveGridPagination.vue";

export default {
    name: "LiveGrid",
    components: {LiveGridPagination, LiveGridFilter, ProductCard},
    props: {
        url: {
            type: String,
            required: true
        },
        category: {
            type: String,
        },
        filterValues: {
            type: String,
            required: true
        },
        selectedValues: {
            type: Object,
            default: []
        }
    },
    data() {
        return {
            table: {
                search: false,
                foot: false,
                responsive: false,
                serverSide: true,
                limits: [18, 36, 48, 96],
                rows: [],
                filtered: [],
            },
            grid: {},
            filters: {},
            selected: {},
            orders: {},
            filterDelay: null,
            loading: true,
            pagination: {
                page: 0,
                total: 0,
                filtered: 0,
                pages: 0,
                offset: 0,
                limit: 18,
            },
            loaded: false,
            tableCycle: null,
        }
    },
    mounted() {
        const data = this.data || {};
        this.table.search = data.hasOwnProperty('columns') && !!this.columns.filter(column => column.searchable).length;
        Object.assign(this.table, data);
        this.table.rows = data.rows || [];
        this.filters = JSON.parse(this.filterValues);
        this.selected = this.selectedValues;
        if (this.selected.hasOwnProperty('category')) {
            const slug = this.selected.category;
            this.filters.categories = this.filters.categories.map(category => {
                category.checked = category.slug === slug;
                return category;
            });
        }
        if (this.selected.hasOwnProperty('country')) {
            const slug = this.selected.country;
            this.filters.countries = this.filters.countries.map(country => {
                country.checked = country.slug === slug;
                return country;
            });
        }
        if (this.selected.hasOwnProperty('q')) {
            this.filters.search = this.selected.q;
        }
        if (this.selected.hasOwnProperty('sale')) {
            this.filters.sale = this.selected.sale;
        }
        this.updateTable(true);
        this.loaded = true;
    },
    methods: {
        updateTable(firstTime = false) {
            clearTimeout(this.tableCycle);
            this.loading = true;
            const filters = _.clone(this.filters);
            filters.categories = this.getSelectedCategories(filters.categories);
            filters.countries = this.getSelectedCountries(filters.countries);

            updateQueryParam('search', filters.search);
            if(!filters.search){
                updateQueryParam('search', '');
            }
            updateQueryParam('stock', filters.stock);
            updateQueryParam('sale', filters.sale);


            gridList(this.url, {
                filters,
                orders: this.orders,
                limit: this.pagination.limit,
                offset: this.pagination.offset,
            }).then(response => {
                this.pagination.total = response.data.pagination.total;
                this.pagination.filtered = response.data.pagination.filtered;
                this.pagination.pages = Math.ceil(this.pagination.total / this.pagination.limit);
                this.$refs["anchor"].scrollIntoView({behavior: "smooth"})
                this.tableCycle = setTimeout(() => {
                    this.loading = false;
                }, 1000);
                const page = getQueryParam('page', this.pagination.page);
                if (firstTime && page > 1) {
                    this.changePage(parseInt(page));
                    return;
                }
                if (response.data.rows.length === 0 && this.pagination.page > 1) {
                    this.changePage(1);
                    return;
                }
                this.table.filtered = response.data.rows;

            })
        },
        order(column) {
            const order = column.order === 1 ? -1 : 1;
            this.columns.map(col => col.order = column.name === col.name ? order : 1)
            this.orders[column.name] = order;
            this.updateTable();

        },
        changePage(page) {
            if (page < 1 || page > this.pagination.pages || page === this.pagination.page - 1) return;
            this.pagination.page = page - 1;
            this.pagination.offset = this.pagination.page * this.pagination.limit;
            updateQueryParam('page', page);
            this.updateTable();
        },
        updatePagination(filtered = 0) {
            this.pagination.total = this.table.rows ? this.table.rows.length : 0;
            this.pagination.filtered = filtered;
            this.pagination.pages = Math.ceil(this.pagination.filtered / this.pagination.limit);
        },
        updateLimit() {
            this.pagination.page = 0;
            this.pagination.offset = 0;
            this.updateTable();
        },
        updateOrder() {
            this.updateTable();
        },
        updateHandler(e) {
            this.loading = false;
            if (e === 'loading') {
                this.loading = true;
            } else if (e === 'finish') {
                this.updateTable();
            }
        },
        updateFilters(filters) {
            this.filters = filters;
            this.updateTable();
        },
        getSelectedCategories(categories) {
            let selected = [];
            categories.forEach(category => {
                if (category.checked) {
                    selected.push(category.slug)
                }
            })

            if (this.selectedValues.hasOwnProperty('category') && selected.indexOf(this.selectedValues.category) < 0 && selected.length === 0) {
                selected.push(this.selectedValues.category);
            }

            return selected
        },
        getSelectedCountries(countries) {
            let selected = [];
            countries.forEach(country => {
                if (country.checked) {
                    selected.push(country.slug)
                }
            })
            if (this.selectedValues.hasOwnProperty('country') && selected.indexOf(this.selectedValues.country) < 0) {
                selected.push(this.selectedValues.country);
            }

            return selected
        }
    }
}
</script>

<template>
    <div class="row live-grid" :class="{loading}" v-cloak>
        <div class="col-12 col-lg-3">
            <live-grid-filter v-if="loaded" :filter-values="filters" :selected-values="selected"
                              @filters="updateFilters($event)"/>
        </div>
        <div class="col">
            <div ref="anchor" class="anchor"></div>
            <div class="row">
                <div class="col-auto">
                    {{
                        $t('table.result', {
                            from: pagination.offset + 1,
                            to: pagination.offset + pagination.limit,
                            entries: pagination.total
                        })
                    }}
                </div>
                <div class="col-auto ms-auto">
                    <!--                    <select class="form-select w-auto" v-model.number="pagination.limit"-->
                    <!--                            @change="updateOrder()"-->
                    <!--                            :title="$t('table.limits')">-->
                    <!--                        <option v-for="limit in table.limits">{{ limit }}</option>-->
                    <!--                    </select>-->
                </div>
                <div class="col-auto">
                    <select class="form-select w-auto" v-model.number="pagination.limit"
                            @change="updateLimit()"
                            :title="$t('table.limits')">
                        <option v-for="limit in table.limits">{{ limit }}</option>
                    </select>
                </div>
            </div>
            <div class="row my-3">
                <div class="col col-md-6 col-lg-6 col-xl-4 mb-3" v-for="row in table.filtered">
                    <product-card :product="row" :key="row.slug"/>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-center mb-3">
                    <live-grid-pagination :current="pagination.page + 1" :last="pagination.pages"
                                          @change-page="changePage($event)"></live-grid-pagination>
                </div>
                <div class="col-auto ms-auto">
                    <select class="form-select w-auto" @change="changePage(parseInt($event.target.value))"
                            :title="$t('table.pages')">
                        <option v-for="n in pagination.pages">{{ n }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.live-grid {
    position: relative;

    &.loading:after {
        content: 'Loading ...';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        border-radius: 5px;
        background: rgba(#000, 0.3);
    }
}

.anchor {
    scroll-margin-top: 300px;
}
</style>
