<script>

import FInput from "../inputs/FInput.vue";
import FRangeInput from "../inputs/FRangeInput.vue";
import FCheckbox from "../inputs/FCheckbox.vue";
import FNested from "../inputs/FNested.vue";
import _ from "lodash";

export default {
  name: "LiveGridFilter",
  components: {FNested, FCheckbox, FRangeInput, FInput},
  emits: ['filters'],
  props: {
    filterValues: {
      type: Object,
      required: true,
    },
    selectedValues: {
      type: Object,
      default: []
    },
  },
  data() {
    return {
      sendFilter: null,
      filters: {},
      priceMin: 0,
      priceMax: 0,
      loaded: false,
    }
  },
  mounted() {
    this.filters = _.clone(this.filterValues);
    [this.priceMin, this.priceMax] = this.filters.prices;
    this.loaded = true;
  },
  methods: {
    updateFilter() {
      this.$emit('filters', this.filters)
    },
    filterCycle() {
      clearTimeout(this.sendFilter);
      this.sendFilter = setTimeout(this.updateFilter, 750);
    },
    updateSearchValue(value) {
      this.filters.search = value;
      if (value.length > 2) {
        this.filterCycle();
      }
    },
    updatePricesValue(prices) {
      this.filters.prices = prices;
      this.filterCycle();
    },
    updateSaleValue(checkbox) {
      this.filters.sale = checkbox;
      this.filterCycle();
    },
    updateStockValue(checkbox) {
      this.filters.stock = checkbox;
      this.filterCycle();
    },
    updateCategoriesValue(slug) {
      this.filters.categories = this.filters.categories.map(category => {
        category.checked = category.slug === slug ? !category.checked : category.checked;
        return category;
      });
      this.filterCycle();
    },
    updateCountriesValue(slug) {
      this.filters.countries = this.filters.countries.map(country => {
        country.checked = country.slug === slug ? !country.checked : country.checked;
        return country;
      });
      this.filterCycle();
    }
  },
  computed: {
    madeInGeorgia() {
      if (this.filters.countries && this.filters.countries.length) {
        return this.filters.countries.find(country => country.slug === 'georgia').checked;
      }
      return false;
    }
  }
}
</script>

<template>
  <section class="mb-3">
    <div class="lead mb-3 fw-bold">{{ $t('filters.search') }}</div>
    <f-input :options="{label:'', value: selectedValues.q, placeholder:$t('filters.product.name')}"
             @update-value="updateSearchValue($event)"/>
  </section>

  <section class="mb-3">
    <div class="lead mb-3 fw-bold">{{ $t('filters.price') }}</div>
    <f-range-input v-if="loaded" :max="priceMax" :min="priceMin" @update="updatePricesValue($event)"/>
  </section>

  <div class="mb-3">
    <f-checkbox :options="{label:$t('filters.sale'), checked: selectedValues.sale}" @update-checkbox="updateSaleValue($event)"/>
  </div>
  <div class="mb-3">
    <f-checkbox :options="{label:$t('filters.available')}" @update-checkbox="updateStockValue($event)"/>
  </div>
<!--  <div class="mb-3">-->
<!--    <f-checkbox :options="{label:'დამზადებულია საქართველოში', checked: madeInGeorgia}"-->
<!--                @update-checkbox="updateCountriesValue('georgia')"/>-->
<!--  </div>-->
  <section class="mb-3">
    <div class="lead mb-3 fw-bold" v-if="filters.categories && filters.categories.length ">
      კატეგორიები
    </div>

    <div class="list-group">
      <template v-for="category in filters.categories">
        <a :href="category.url" v-if="category.items < 1" class="list-group-item text-primary"
           @click.prevent="updateCategoriesValue(category.slug)">
          {{ category.name }} ({{ category.products }}) <i class="bi float-end"
                                                           :class="{'bi-check' : category.checked}"></i>
        </a>
        <a :href="category.url" v-if="category.items > 0"
           class="list-group-item text-primary">
          {{ category.name }} ({{ category.products }})
        </a>
      </template>
    </div>
  </section>


<!--  <section class="mb-3">-->
<!--    <div class="lead mb-3 fw-bold" v-if="filters.countries && filters.countries.length ">-->
<!--      ქვეყნები-->
<!--    </div>-->

<!--    <div class="list-group">-->
<!--      <template v-for="country in filters.countries">-->
<!--        <a :href="country.url" v-if="country.items < 1" class="list-group-item text-primary"-->
<!--           @click.prevent="updateCountriesValue(country.slug)">-->
<!--          {{ country.name }} ({{ country.products }})-->
<!--          <i class="bi float-end" :class="{'bi-check' : country.checked}"></i>-->
<!--        </a>-->
<!--      </template>-->
<!--    </div>-->
<!--  </section>-->

</template>

<style scoped>

</style>
