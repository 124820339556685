<script>

export default {
    name: "CartQty",
    props: {
        qty: {
            type: Number,
            default: 1
        },
        maxQty: {
            type: Number,
            default: 1
        },
    },
    emits: ['update'],

    methods: {
        updateQty(number) {
            let qty = this.qty + number;
            qty = qty < 1 ? 1 : qty;
            qty = qty > this.maxQty ? this.maxQty : qty;
            this.$emit('update', qty);
        }
    }
}
</script>

<template>
    <div class="btn-group align-items-center">
        <button aria-label="decrease" class="btn btn-outline-dark" @click="updateQty(-1)">
            <i class="bi bi-dash fs-4"></i>
        </button>
        <button aria-label="qty" class="btn btn-outline-dark fs-4" style="width: 55px;" readonly>{{ qty }}</button>
        <button aria-label="increase" class="btn btn-outline-dark" @click="updateQty(1)">
            <i class="bi bi-plus fs-4"></i>
        </button>
    </div>
</template>

<style scoped>

</style>
