<template>
    <ul class="nav nav-pills mb-3">
        <template v-for="locale in locales">
            <li class="nav-item me-1">
                <a class="nav-link" :class="{active:currentLocale === locale}" href="#"
                   @click.prevent="currentLocale = locale">{{
                        locale
                    }}</a>
            </li>
        </template>
    </ul>

    <template v-for="(locale, i) in locales">
        <div :class="{'d-none':currentLocale !== locale}" class="bg-light p-3 rounded">
            <div v-for="(item, k) in items">
                <label v-if="item.type === 'f-editor'">{{getInputLabel(item.label, locale)}}</label>
                <component :is="item.type" :name="getInputName(item.name, locale)"
                           v-if="item.value !== undefined"
                           :key="key"
                           @update-value="updateTexts($event, item.name, locale)"
                           v-bind:options="{name:getInputName(item.name, locale), required: item.required, label: getInputLabel(item.label, locale), value: getValue(item.name, locale)}"></component>
            </div>
            <button @click.prevent="copyTexts(locale)" class="btn btn-success">Use this language for all</button>
        </div>
    </template>
</template>

<script>
import FText from "./FText";
import FEditor from "./FEditor";
import FInput from "./FInput";

export default {
    name: "FTranslation",
    emits: ['update'],
    components: {FText, FInput, FEditor},
    props: {
        locales: {
            type: Array,
            default: [],
        },
        items: {
            type: Array,
            default: [],
        },
        translated: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            currentLocale: null,
            texts: {},
            key: 0
        }
    },
    mounted() {
        this.currentLocale = this.locales[0];
        this.locales.map((locale, i) => {
            this.texts[locale] = {}
            this.items.map((item, k) => {
                item.value = this.findTranslation(item.name, locale);
                this.texts[locale][item.name] = this.findTranslation(item.name, locale);
            })
        })
    },
    methods: {
        getInputName(name, locale) {
            return `${locale}[${name}]`;
        },
        getInputLabel(label, locale) {
            return `${label} - ${locale}`;
        },
        updateTexts(value, name, locale) {
            this.texts[locale][name] = value;
        },
        copyTexts(locale) {
            const original = this.texts[locale];
            const keys = Object.keys(this.texts);
            for (let i = 0; i < keys.length; i++) {
                this.texts[keys[i]] = original;
            }
            this.key++;
        },
        findTranslation(name, locale) {
            if (!this.translated.hasOwnProperty(locale)) return '';
            if (!this.translated[locale].hasOwnProperty(name)) return '';
            return this.translated[locale][name];
        }
    },
    computed: {
        getValue() {
            let vm = this;
            return (name, locale) => {
                if (vm.texts.hasOwnProperty(locale) && vm.texts[locale].hasOwnProperty(name)) {
                    return vm.texts[locale][name];
                }
                return '';
            }
        }
    }
}
</script>

<style scoped>

</style>
