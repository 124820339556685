<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper/modules';
import ProductCard from "../ecommerece/ProductCard.vue";

export default {
    name: "SwiperWrapper",
    props: ['slides'],
    components: {
        ProductCard,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation],
        };
    },
}
</script>

<template>
    <swiper
        :loop="true"
        :modules="modules"
        :slides-per-view="1.5"
        :space-between="20"
        :navigation="true"
        :breakpoints="{
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1440: {
                slidesPerView: 5,
            },
        }"
    >
        <swiper-slide v-for="slide of slides">
            <product-card :product="slide"></product-card>
        </swiper-slide>
    </swiper>
</template>

<style scoped>

</style>
