<script>
export default {
    name: "SiteMenu",
    props: {
        menu: {
            type: Array,
            required: true
        },
        shortMenu: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            hidden: true,
            items: []
        }
    },
    methods: {
        toggleMenu() {
            this.hidden = !this.hidden
        },
        selectSubMenu(items) {
        }
    },
}
</script>

<template>
    <section class="category-section d-flex flex-column flex-lg-row">
        <div class="my-3 my-xl-auto px-lg-3 text-center text-lg-start">
            <a href="#" class="h5 text-white text-decoration-none mb-0 mx-auto d-lg-flex w-100 flex align-items-center"
               @click.prevent="toggleMenu">
                <img src="../../assets/burger-menu.svg" class="me-2" alt="burger-menu-icon">
                <span class="category-name">{{ $t('header.menu.all-categories') }}</span>
            </a>
        </div>
        <ul class="nav me-auto align-items-center d-none d-xl-flex py-lg-3 gap-1 flex-fill justify-content-evenly">
            <li class="nav-item" v-for="item of shortMenu">
                <a :href="item.attribute" class="category-card">
                    <img v-if="item.image" :src="item.image.url" :alt="item.attribute">
                    <span>{{ item.title }}</span>
                </a>
            </li>
        </ul>
        <div :hidden="hidden" class="full-menu-panel">
            <div class="row">
                <div class="col-12 col-lg-3" v-if="menu">
                    <ul class="nav flex-column d-lg-none">
                        <li class="nav-item text-center">
                            <a href="#" class="nav-link mx-auto" @click.prevent="toggleMenu">
                                {{ $t('header.menu.close-menu') }}
                                <i class="bi bi-x-lg"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="row mt-3 g-0 ps-3 d-lg-none">
                        <div class="col-6 mb-3 pe-3" v-for="item of menu">
                            <a :href="item.attribute" class="category-card" @mouseover="items = item.items">
                                <span>{{ item.title }}</span>
                                <img v-if="item.image" :src="item.image.url" :alt="item.attribute" width="32"
                                     height="32">
                            </a>
                        </div>
                    </div>
                    <ul class="nav flex-column d-none d-lg-flex pt-3">
                        <li class="nav-item" v-for="item of menu">
                            <a :href="item.attribute" class="nav-link" @mouseover="items = item.items">
                                {{ item.title }}
                                <i class="bi bi-chevron-right float-end" v-if="item.items.length"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col sub-menu pt-3 d-lg-block d-none">
                    <div class="row text-center d-none d-lg-flex" v-if="items">
                        <div class="col-3 mb-3" v-for="item of items">
                            <a :href="item.attribute" class="btn btn-link text-decoration-none">
                                <img v-if="item.image" :src="item.image.url" :alt="item.attribute" width="64"
                                     height="64"
                                     class="d-block mx-auto">
                                {{ item.title }}
                            </a>
                            <!--                            <ul class="nav flex-column text-start" v-if="item.items.length">-->
                            <!--                                <li class="nav-item" v-for="sub of item.items">-->
                            <!--                                    <a :href="sub.attribute" class="nav-link">-->
                            <!--                                        <small>{{ sub.title }}</small>-->
                            <!--                                    </a>-->
                            <!--                                </li>-->
                            <!--                            </ul>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
section {
    position: relative;
    background: var(--bs-primary);

    .nav-link {
        color: var(--bs-white);
    }
}

.full-menu-panel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--bs-primary);
    z-index: 1050;

    @media (min-width: 1024px) {
        position: absolute;
        height: auto;
        top: 100%;
        border-radius: 13px;
        a {
            &:hover {
                color: var(--bs-secondary) !important;
            }
        }
    }

    .sub-menu {
        min-height: 400px;
        background: #EBF2EA;
        color: black;

        .btn-link {
            &:hover {
                color: rgba(var(--bs-primary), .9) !important;
            }
        }
    }

}

.category-name {
    height: 2rem;
}

.category-section {
    @media (min-width: 1024px) {
        border-radius: 13px;
    }
}

.category-card {
    background: #EBF2EA;
    aspect-ratio: 1/1;
    border-radius: 13px;
    color: var(--bs-primary);
    text-align: center;
    padding: 20px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    text-decoration: none;

    &:focus, &:active, &:hover {
        background: var(--bs-secondary);
    }

    @media (min-width: 1024px) {
        height: 100%;
        align-items: center;
        flex-direction: row;
        aspect-ratio: auto;
        padding: 10px 10px;
        font-size: 12px;
        justify-content: center;
    }

    img {
        object-fit: cover;
        display: block;
        width: auto;
        height: 60px;
        margin: auto auto 0;
        @media (min-width: 1024px) {
            height: 32px;
            margin: 0;
        }
    }
}

</style>
