<template>
    <div v-if="editor">
        <div class="btn-group me-2">
            <button @click.prevent="editor.chain().focus().undo().run()" class="btn btn-light">
                <i class="bi bi-arrow-counterclockwise"></i>
            </button>
            <button @click.prevent="editor.chain().focus().redo().run()" class="btn btn-light">
                <i class="bi bi-arrow-clockwise"></i>

            </button>
        </div>
        <div class="btn-group me-2">
            <button @click.prevent="editor.chain().focus().toggleBold().run()" class="btn"
                    :class=" editor.isActive('bold') ?'btn-primary': 'btn-light'">
                <i class="bi bi-type-bold"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleItalic().run()" class="btn"
                    :class=" editor.isActive('italic') ?'btn-primary': 'btn-light'">
                <i class="bi bi-type-italic"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleStrike().run()" class="btn"
                    :class=" editor.isActive('strike') ?'btn-primary': 'btn-light'">
                <i class="bi bi-type-strikethrough"></i>
            </button>
            <button @click.prevent="editor.chain().focus().unsetAllMarks().run()" class="btn btn-light">
                <i class="bi bi-eraser"></i>
            </button>
        </div>
        <div class="btn-group me-2">
            <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    Color
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" @click.prevent="editor.chain().focus().unsetColor().run()">Normal</a>
                    </li>
                    <li style="background: #958DF1;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#958DF1').run()">Purple</a>
                    </li>
                    <li style="background: #F98181;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#F98181').run()">Red</a>
                    </li>
                    <li style="background: #FBBC88;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#FBBC88').run()">Orange</a>
                    </li>
                    <li style="background: #FAF594;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#FAF594').run()">Yellow</a>
                    </li>
                    <li style="background: #70CFF8;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#70CFF8').run()">Blue</a>
                    </li>
                    <li style="background: #94FADB;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#94FADB').run()">Teal</a>
                    </li>
                    <li style="background: #B9F18D;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().setColor('#B9F18D').run()">Green</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="btn-group me-2">
            <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    Highlight
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#"
                           @click.prevent="editor.chain().focus().unsetHighlight().run()">Normal</a></li>
                    <li style="background: #958DF1;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#958DF1'}).run()">Purple</a>
                    </li>
                    <li style="background: #F98181;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#F98181'}).run()">Red</a>
                    </li>
                    <li style="background: #FBBC88;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#FBBC88'}).run()">Orange</a>
                    </li>
                    <li style="background: #FAF594;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#FAF594'}).run()">Yellow</a>
                    </li>
                    <li style="background: #70CFF8;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#70CFF8'}).run()">Blue</a>
                    </li>
                    <li style="background: #94FADB;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#94FADB'}).run()">Teal</a>
                    </li>
                    <li style="background: #B9F18D;"><a class="dropdown-item" href="#"
                                                        @click.prevent="editor.chain().focus().toggleHighlight({color :'#B9F18D'}).run()">Green</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="btn-group me-2">
            <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    Size
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(16)">Normal</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(8)">8px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(10)">10px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(12)">12px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(14)">14px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(16)">16px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(18)">18px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(20)">20px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(22)">22px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(24)">24px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(32)">32px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(48)">48px</a></li>
                    <li><a class="dropdown-item" href="#" @click.prevent="changeSize(64)">64px</a></li>
                </ul>
            </div>
        </div>
        <div class="btn-group me-2">
            <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    Format
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" @click.prevent="changeFormat(0)">Normal</a></li>
                    <li><a class="dropdown-item fs-1" href="#" @click.prevent="changeFormat(1)">H1</a></li>
                    <li><a class="dropdown-item fs-2" href="#" @click.prevent="changeFormat(2)">H2</a></li>
                    <li><a class="dropdown-item fs-3" href="#" @click.prevent="changeFormat(3)">H3</a></li>
                    <li><a class="dropdown-item fs-4" href="#" @click.prevent="changeFormat(4)">H4</a></li>
                    <li><a class="dropdown-item fs-5" href="#" @click.prevent="changeFormat(5)">H5</a></li>
                    <li><a class="dropdown-item fs-6" href="#" @click.prevent="changeFormat(6)">H6</a></li>
                </ul>
            </div>
        </div>

        <div class="btn-group me-2">
            <button @click.prevent="editor.chain().focus().setTextAlign('left').run()" class="btn"
                    :class=" editor.isActive({ textAlign: 'left' }) ?'btn-primary': 'btn-light'">
                <i class="bi bi-text-left"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setTextAlign('center').run()" class="btn"
                    :class=" editor.isActive({ textAlign: 'center' }) ?'btn-primary': 'btn-light'">
                <i class="bi bi-text-center"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setTextAlign('right').run()" class="btn"
                    :class=" editor.isActive({ textAlign: 'right' }) ?'btn-primary': 'btn-light'">
                <i class="bi bi-text-right"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setTextAlign('justify').run()" class="btn"
                    :class=" editor.isActive({ textAlign: 'justify' }) ?'btn-primary': 'btn-light'">
                <i class="bi bi-justify"></i>
            </button>
            <button @click.prevent="editor.chain().focus().unsetTextAlign().run()" class="btn btn-light">
                <i class="bi bi-eraser"></i>
            </button>
        </div>

        <div class="btn-group me-2">
            <button @click.prevent="editor.chain().focus().toggleCode().run()" class="btn"
                    :class=" editor.isActive('code') ?'btn-primary': 'btn-light'">
                <i class="bi bi-code"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleCodeBlock().run()" class="btn"
                    :class=" editor.isActive('codeBlock') ?'btn-primary': 'btn-light'">
                <i class="bi bi-code-square"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleBulletList().run()" class="btn"
                    :class=" editor.isActive('bulletList') ?'btn-primary': 'btn-light'">
                <i class="bi bi-list-ul"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleOrderedList().run()" class="btn"
                    :class=" editor.isActive('orderedList') ?'btn-primary': 'btn-light'">
                <i class="bi bi-list-ol"></i>
            </button>
            <button @click.prevent="editor.chain().focus().toggleBlockquote().run()" class="btn"
                    :class=" editor.isActive('blockquote') ?'btn-primary': 'btn-light'">
                <i class="bi bi-blockquote-left"></i>
            </button>
            <button @click.prevent="editor.chain().focus().clearNodes().run()" class="btn btn-light">
                <i class="bi bi-eraser"></i>
            </button>
        </div>
        <div class="btn-group me-2">
            <button @click.prevent="editor.chain().focus().setHorizontalRule().run()" class="btn btn-light">
                <i class="bi bi-dash-lg"></i>
            </button>
            <button @click.prevent="editor.chain().focus().setHardBreak().run()" class="btn btn-light">
                <i class="bi bi-arrow-return-left"></i>
            </button>
        </div>

        <div class="btn-group me-2">
            <button @click.prevent="theme = theme === 'dark'?'light':'dark'" class="btn btn-light">
                <i class="bi" :class="theme === 'dark'?'bi-circle':'bi-circle-fill'"></i>
            </button>
        </div>

        <div class="btn-group me-2">
            <button @click.prevent="addImage" class="btn btn-light">
                <i class="bi bi-image"></i>
            </button>
        </div>

    </div>

    <editor-content :editor="editor" :class="theme" class="tiptap my-3"/>

    <textarea v-if="editor" :name="name" @change="change()" hidden>{{editor.getHTML()}}</textarea>
</template>

<script>

import {Editor, EditorContent} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Image from '@tiptap/extension-image';
import {Color} from "@tiptap/extension-color";
import {TextStyle} from "@tiptap/extension-text-style";
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import {FontSize} from "../../utils";

export default {
    name: "FEditor",
    emits: ['update-value'],
    components: {
        EditorContent,
    },
    props: {
        options: {
            type: Object,
            required: false
        },
        name: {
            type: String,
            default: 'content'
        }
    },
    data() {
        return {
            editor: null,
            theme: 'light',
            config: {}
        }
    },
    mounted() {
        this.config = Object.assign({}, this.config, this.options);
        this.editor = new Editor({
            content: this.config.value,
            onUpdate: ({editor}) => {
                {
                    return this.$emit('update-value', editor.getHTML());
                }
            },
            extensions: [
                FontSize,
                StarterKit,
                Image,
                TextStyle,
                Color,
                Highlight.configure({multicolor: true}),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
        })
    },
    methods: {
        changeFormat(value) {
            if (value) {
                this.editor.chain().focus().toggleHeading({level: value}).run();
            } else {
                this.editor.chain().focus().setParagraph().run();
            }
        },
        changeSize(value) {
            if (value) {
                this.editor.chain().focus().setFontSize(value).run();
            } else {
                this.editor.chain().focus().setParagraph().run();
            }
        },
        addImage() {
            const url = window.prompt('URL')

            if (url) {
                this.editor.chain().focus().setImage({src: url}).run()
            }
        },
    }
}
</script>

<style lang="scss">
.tiptap {
    & > div {
        background: var(--bs-light);
        color: var(--bs-dark);
        border: 1px solid var(--bs-dark);
        outline: none !important;
        padding: .5rem;
        border-radius: .25rem;
    }

    &.dark > div {
        background: var(--bs-dark);
        color: var(--bs-light);
        border: 1px solid var(--bs-light);
    }
}
</style>
