<script>
export default {
    name: "ProductImageZoom",
    props: ['image'],
    data() {
        return {
            showZoom: false,
            x: 0,
            y: 0,
        }
    },
    methods: {
        mouseEnter() {
            this.showZoom = true;
        },
        mouseLeave() {
            this.showZoom = false;
        },
        mouseMove(e) {
            const rect = e.target.getBoundingClientRect();

            this.x = (e.clientX - rect.left) / rect.width * 100;
            this.y = (e.clientY - rect.top) / rect.height * 100;
        }
    },
    computed: {
        backgroundImage() {
            return `url("${this.image}")`;
        },
        backgroundPosition() {
            return `${this.x}% ${this.y}%`;
        }
    }
}
</script>

<template>
    <div class="position-relative text-center border border-primary bg-white" @mouseenter="mouseEnter"
         @mouseleave="mouseLeave"
         @mousemove="mouseMove($event)">
        <img :src="image" alt="product-image-zoom" class="img-fluid">
        <div class="zoomed border border-3 border-black bg-white" :class="{showZoom}"
             :style="{backgroundImage, backgroundPosition}">
        </div>
    </div>
</template>

<style scoped lang="scss">
img {
    max-height: 60vh;
    cursor: zoom-in;
}

.zoomed {
    position: absolute;
    top: 0;
    left: 102%;
    width: 45vw;
    height: 60vh;
    display: none;
    background-size: 150%;
    background-repeat: no-repeat;
    z-index: 2000;

    &.showZoom {
        display: block;
    }
}
</style>
