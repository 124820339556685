<template>
    <label class="form-label" v-if="config.label.length">{{ config.label }}</label>
    <select v-bind="config" @change="$emit('updateSelect', $event.target.value);">
        <option v-if="config.default.length" :value="config.defaultValue">{{ config.default }}</option>
        <option v-for="item in items" :value="getItemValue(item)">{{ getItemLabel(item) }}</option>
    </select>
</template>

<script>
export default {
    name: "FSelect",
    emits: ['updateSelect'],
    props: {
        options: {
            type: Object,
            default: {}
        },
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            config: {
                label: '',
                required: false,
                name: 'input',
                placeholder: '',
                autocomplete: 'off',
                value: '',
                class: 'form-select',
                default: '',
                defaultValue: null,
            }
        }
    },
    mounted() {
        this.config = Object.assign({}, this.config, this.options);
        if (this.config.value === '') {
            this.config.value = this.config.defaultValue || this.config.default;
        }
    },
    methods: {
        getItemValue(item) {
            if (typeof item === 'object' && item.hasOwnProperty('id'))
                return item.id;
            return item;
        },
        getItemLabel(item) {
            if (typeof item === 'object') {
                if (item.hasOwnProperty('label'))
                    return item.label;
                else if (item.hasOwnProperty('name'))
                    return item.name;
            }
            return item;
        },
    }
}
</script>

<style scoped>

</style>
