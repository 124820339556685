<script>
export default {
    data() {
        return {
            visible: false
        }
    },
    methods: {
        toggleVisibility() {
            const input = this.$refs.password;
            if (input.type === 'password') {
                input.type = 'text';
                this.visible = true;
            } else if (input.type === 'text') {
                input.type = 'password';
                this.visible = false;
            }
        }
    }
}
</script>

<template>
    <label for="password" class="form-label">
        {{ $t('register.password') }}
    </label>
    <div class="position-relative">
        <input type="password" ref="password" id="password" class="form-control" name="password"
               required
               :placeholder="$t('register.password')">
        <span :title="!visible ? 'show password?' : 'hide password?'" @click="toggleVisibility"
              class="password-toggle-icon" id="password-addon"
              style="border-left: none; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                <i class="bi" :class="[!visible ? 'bi-eye-fill' : 'bi-eye-slash']"/>
        </span>
    </div>

    <p class="text-muted small text-nowrap py-2">
        {{ $t('register.password.help') }}
    </p>
</template>
