<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "FRangeInput",
    emits: ['update'],
    props: {
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            minAngle: null,
            maxAngle: null,
            cycle: null,
        }
    },
    mounted() {
        this.maxAngle = this.max;
        this.minAngle = this.min;
    },
    methods: {
        updateValues() {
            const temp = this.minAngle;
            this.minAngle = Math.min(this.minAngle, this.maxAngle);
            this.maxAngle = Math.max(temp, this.maxAngle);
            this.cycle = setTimeout(()=>{
                this.$emit('update', [this.minAngle, this.maxAngle]);
            }, 500);
        }
    },
    watch: {
        minAngle: {
            handler(n, o) {
                if (o === null) return;
                this.updateValues()
            },
            deep: true
        },
        maxAngle: {
            handler(n, o) {
                if (o === null) return;
                this.updateValues()
            },
            deep: true
        },
    }
})
</script>

<template>
    <div class="range-values mb-3">
        <input class="form-control me-3" step="0.01" type="number" v-model="minAngle">
        <input class="form-control" step="0.01" type="number" v-model="maxAngle">
    </div>
    <div class="range-slider">
        <input type="range" :min="min" :max="max" class="form-range" v-model="minAngle" step="0.01">
        <input type="range" :min="min" :max="max" class="form-range" v-model="maxAngle" step="0.01">
    </div>
</template>

<style scoped lang="scss">
.range-slider {
    position: relative;
    height: 30px;

    input {
        position: absolute;
        left: 0;
        top: 0;

        &::-webkit-slider-thumb {
            z-index: 2;
            position: relative;
        }
    }
}

.range-values {
    display: flex;
    justify-content: space-between;
}
</style>
