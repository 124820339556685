<script>
import {cart, cartAddOrUpdate} from "../../utils";

export default {
    name: 'CartButton',
    props: {
        product: {
            type: String,
            required: true
        },
        qty: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            default: "short"
        }
    },
    data() {
        return {
            selectedQty: 1,
        }
    },
    methods: {
        addToCart(fn) {
            if (!cart.items) return;
            const index = cart.items.findIndex(item => item.slug === this.product);
            if (index > -1) {
                this.$toast.warning(this.$t('cart.item.already-in-cart'));
            } else {
                cartAddOrUpdate({slug: this.product, qty: this.selectedQty}).then(response => {
                    cart.items.push(response.data);
                    if (typeof fn === 'function') {
                        fn();
                    }
                    this.$toast.success(this.$t('cart.item.added'));
                }).catch(r => {
                    this.$toast.error(this.$t('cart.item.error'));
                })
            }
        },
        updateQty(qty) {
            if (this.selectedQty + qty > this.qty) {
                return this.selectedQty = this.qty;
            }
            if (this.selectedQty + qty < 1) {
                return this.selectedQty = 1;
            }
            this.selectedQty += qty;
        },
        isNumber(e) {
            isNaN(parseInt(String.fromCharCode(e.which))) && e.preventDefault();
        }
    }
}
</script>

<template>
    <div v-if="type === 'short'" class="btn-group align-items-center">
        <div class="qty-selector rounded-end-0" v-if="qty>0">
            <button aria-label="decrease" @click="updateQty(-1)">-</button>
            <span>{{ selectedQty }}</span>
            <button aria-label="increase" @click="updateQty(1)">+</button>
        </div>
        <button aria-label="add-to-cart" class="btn btn-primary" :disabled="qty < 1"
                :class="{'btn-primary': qty > 0, 'btn-danger': qty < 1}"
                @click="addToCart()">
            <i class="bi bi-cart-plus"></i>
        </button>
    </div>
    <div v-if="type === 'long'" class="btn-group align-items-center type-long">
        <div class="qty-selector rounded-end-0 align-items-center" v-if="qty>0">
            <button aria-label="decrease" @click="updateQty(-1)">-</button>
            <span>{{ selectedQty }}</span>
            <button aria-label="increase" @click="updateQty(1)">+</button>
        </div>
        <button aria-label="add-to-cart" class="btn btn-primary" :disabled="qty < 1"
                :class="{'btn-primary': qty > 0, 'btn-danger': qty < 1}"
                @click="addToCart()">
            <i class="bi bi-cart-plus"></i>
        </button>
    </div>
</template>

<style scoped lang="scss">
.qty-selector {
    display: flex;
    border: 1px solid var(--bs-dark);
    border-right: 0;
    border-radius: 5px;
    padding: 6px 10px;
    background: var(--bs-secondary);

    span {
        width: 30px;
        border: 0;
        font-weight: bold;
        background: transparent;
        outline: none;
        text-align: center;
    }

    button {
        border: 0;
        font-weight: bold;
        background: transparent;
        padding: 0;
    }
}

.type-long {
    .qty-selector {
        height: 100%;

        span {

        }
    }

    button {
        height: 100%;

        span {
            font-size: 9px;
        }
    }
}
</style>
