import {reactive} from "vue";
import {Extension} from "@tiptap/vue-3";


export const cart = reactive({
    items: []
})

export const cartList = async () => {
    return await axios.get('/api/cart/list')
}

export const cartAddOrUpdate = async (item) => {
    return await axios.post('/api/cart/item', item)
}
export const cartRemoveItem = async (slug) => {
    return await axios.delete('/api/cart/item', {
        data: {slug}
    });
}

export const gridList = async (url, params) => {
    return await axios.get(url, {
        params
    })
}

export const searchList = async (url, params) => {
    return await axios.get(url, {
        params
    })
}

export const userAddressList = async () => {
    return await axios.get('/api/user/address/list')
}
export const userAddressCreate = async (params) => {
    return await axios.post('/api/user/address/create', params)
}

export const updateQueryParam = (name, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    window.history.replaceState(null, null, url);
}

export const getQueryParam = (name, defaultValue = null) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(name) || defaultValue;
}

export const FontSize = Extension.create({
    name: 'fontSize',
    addOptions() {
        return {
            types: ['textStyle'],
        };
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
                        renderHTML: attributes => {
                            if (!attributes.fontSize) {
                                return {};
                            }
                            return {
                                style: `font-size: ${attributes.fontSize}`,
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            setFontSize: fontSize => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: fontSize + "px" })
                    .run();
            },
            unsetFontSize: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .run();
            },
        };
    },
});
