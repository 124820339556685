<template>
    <div>
        <label class="form-check-label" v-if="config.label.length">
            <input class="form-check-input" v-model="config.checked" v-bind="config"
                   @change="updateCheck">
            {{ config.label }}
        </label>
        <div class="valid-feedback">{{ $t('form.valid') }}</div>
        <div class="invalid-feedback">{{ $t('form.invalid') }}</div>
    </div>
</template>

<script>
export default {
    name: "FCheckbox",
    props: ['options'],
    emits: ['updateCheckbox'],
    data() {
        return {
            defaultConfig: {
                label: '',
                required: false,
                name: 'input',
                placeholder: '',
                autocomplete: 'off',
                type: 'checkbox',
                checked: false,
            }
        }
    }, methods: {
        updateCheck() {
            return this.$emit('updateCheckbox', this.config.checked);
        }
    },
    computed: {
        config() {
            const config = Object.assign({}, this.defaultConfig, this.options);
            config.checked = !!config.checked;
            return config;
        }
    }
}
</script>

<style scoped>

</style>
