<script>
import {cart} from "../../utils";

export default {
    name: "CartModalButton",
    computed: {
        cart() {
            return cart
        }
    }
}
</script>

<template>
    <button aria-label="show-cart" type="button" class="btn border-0 position-relative" data-bs-toggle="modal"
            data-bs-target="#cart-modal">
        <img src="../../../assets/cart.svg" alt="cart-icon">
        <span class="badge bg-primary text-white">
            <span>{{ cart.items.length }}</span>
        </span>
    </button>
</template>

<style scoped lang="scss">
.badge {
    position: absolute;
    top: 20%;
    left: 80%;
    width: 18px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
    line-height: 18px;
}
</style>
