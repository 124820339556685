<script>
import ProductImageZoom from "./ProductImageZoom.vue";

export default {
    name: "ProductImages",
    components: {ProductImageZoom},
    props: {
        images: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            currentImage: null,
            currentImageZoomed: null,
            openFullscreen: false,
        }
    },
    mounted() {
        if (this.images.length) {
            this.currentImage = this.images[0];
        }
    }
}
</script>

<template>
    <product-image-zoom :image="currentImage"
                        @click="openFullscreen = true; currentImageZoomed = currentImage"></product-image-zoom>
    <div>
        <ul class="image-list list-unstyled mt-3">
            <li v-for="image in images">
                <img :src="image" alt="product-image" class="mini-image border" @click="currentImage = image" :class="{
                    'border-secondary': currentImage !== image,
                    'border-primary': currentImage === image
                }">
            </li>
        </ul>
    </div>

    <div class="fullscreen" :class="{open: openFullscreen}">
        <div class="close" @click="openFullscreen = false">
            <i class="bi bi-x-circle"></i>
        </div>
        <div class="wrapper text-center">
            <img :src="currentImageZoomed" alt="" class="image">
            <div>
                <ul class="image-list list-unstyled mt-3">
                    <li v-for="image in images">
                        <img :src="image" alt="product-image" class="mini-image border" @click="currentImageZoomed = image" :class="{
                    'border-secondary': currentImageZoomed !== image,
                    'border-primary': currentImageZoomed === image
                }">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.image-list {
    max-width: 100%;
    overflow: auto;
    display: flex;


    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        height: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #F7E06C;
    }

    li {
        margin: 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .mini-image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            cursor: pointer;
        }
    }
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    justify-content: center;
    align-items: center;
    z-index: 1050;
    display: none;

    &.open {
        display: flex;
    }

    .close {
        position: absolute;
        right: 5%;
        top: 5%;

        i {
            font-size: 4rem;
            color: white;
            cursor: pointer;
        }
    }

    .wrapper {
        width: 60vw;

        .image {
            height: 80vh;
            width: auto;
        }
    }
}
</style>
