<script>
import {searchList} from "../utils";
import CartQty from "./ecommerece/CartQty.vue";
import CartRemoveItem from "./ecommerece/CartRemoveItem.vue";

export default {
    name: "LiveSearch",
    components: {CartRemoveItem, CartQty},
    props: ['url', 'searchUrl'],
    data() {
        return {
            search: '',
            searchTimeOut: null,
            searchFocused: false,
            loading: false,
            products: {
                total: 0,
                items: []
            },
        }
    },
    methods: {
        getData() {
            searchList(this.url, {q: this.search}).then(response => {
                this.products = response.data;
                this.loading = false;
            })
        },
        cycle() {
            this.loading = true;
            clearTimeout(this.searchTimeOut);
            this.searchTimeOut = setTimeout(() => {
                this.getData()
            }, 700);
        },
        searchInProductPage() {
            if (this.search.length > 2) {
                window.location.href = this.productSearchLink
            }
        },
        viewProduct(link) {
            window.location.href = link;
        },
        some() {
            this.searchFocused = false;
        }
    },
    computed: {
        productSearchLink() {
            return this.searchUrl.replace('_q', this.search);
        }
    },
    watch: {
        search(newValue) {
            if (newValue.length > 2) {
                return this.cycle();
            }
            clearTimeout(this.searchTimeOut);
        }
    }
}
</script>

<template>
    <section class="search" v-click-outside="some">
        <div class="input-group">
            <input type="text" class="form-control border-0" v-model="search" placeholder=""
                   aria-label="Search"
                   @focus="searchFocused = true" @keydown.enter="searchInProductPage">
            <button @click="searchInProductPage" class="btn btn-search btn-secondary" name="search">
                {{ $t('header.search.button.title') }}
            </button>
        </div>
        <div class="result">

            <div class="wrapper bg-white text-center my-3 rounded-2" v-if="searchFocused && search.length > 0">
                <div v-if="products.items.length" class="d-flex flex-column w-100">
                    <div class="m-3 text-end">
                        <a :href="productSearchLink" class="btn btn-primary">
                            {{ $t('header.search.view.all.button') }}
                        </a>
                    </div>

                    <div class="d-flex flex-column pb-3">
                        <div v-for="item in products.items" :key="item.slug" @click="viewProduct(item.view)"
                             class="product-item align-items-center overflow-hidden">
                            <div class="image">
                                <a :href="item.view"><img :src="item.image" :alt="item.slug"></a>
                            </div>
                            <div class="name mx-3">
                                <span class="h4 me-2">{{ item.title }}</span>
                                <span class="small">{{
                                        $t('header.search.' + (item.qty > 0 ? 'in-stock' : 'out-stock'))
                                    }}</span>
                            </div>
                            <div class="price me-3">
                                <button aria-label="price" class="btn btn-primary">
                                    {{ item.price.toFixed(2) }}₾
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="message py-4 bg-secondary rounded-3" v-else>
                    <template v-if="search.length > 0 && search.length <= 2">
                        {{ $t('header.search.min-length') }}
                    </template>
                    <template v-else-if="search.length > 2 && !loading && !products.items.length">
                        {{ $t('header.search.no-result', [this.search]) }}
                    </template>
                    <template v-else-if="loading">
                        {{ $t('header.search.loading') }}
                    </template>
                </div>

            </div>

        </div>

    </section>
</template>

<style scoped lang="scss">
.search {

    .result {
        position: relative;

        .wrapper {
            position: fixed;
            left: 0;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            max-height: 70%;
            z-index: 1050;

            @media (min-width: 768px) {
                position: absolute;
                top: 0;
                left: 0;
                max-height: 50vh;
            }


            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                width: 10px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #F7E06C;
            }
        }
    }
}

.product-item {
    display: flex;
    background-color: #fff;

    &:hover {
        background-color: #F2F2F2;
    }

    .image {
        img {
            max-width: 100px;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .price {
        margin-left: auto;
    }
}

.btn-search {
    color: #4a4a4b;
}
</style>
