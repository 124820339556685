<script>
import {userAddressCreate, userAddressList} from "../../utils";

export default {
    name: "UserAddress",
    props: {
        cities: {
            type: Array,
            default: [],
        },
        address: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            modal: null,
            addresses: [],
            selected: null,
            form: {
                name: '',
                city: 1,
                address: '',
                comment: '',
                default: true,
            },
            errors: [],
        }
    },
    mounted() {
        this.modal = new bootstrap.Modal('#new-user-address');
        this.selected = parseInt(this.address);
        this.form.city = this.cities[0].id;
        this.loadAddresses();
    },
    methods: {
        select(id) {
            window.location.href = window.location.href + '?address_id=' + id;
        },
        clear() {
            this.form = {
                name: '',
                city: this.cities[0].id,
                address: '',
                comment: '',
                default: true,
            }
            this.errors = [];
        },
        loadAddresses() {
            userAddressList().then(r => {
                this.addresses = r.data
            }).catch();
        },
        save() {
            userAddressCreate({
                'address_name': this.form.name,
                'city_id': this.form.city,
                'street': this.form.address,
                'comment': this.form.comment,
                'default': this.form.default,
            }).then(r => {
                this.modal.hide();
                this.clear();
                this.loadAddresses();
            }).catch(r => {
                this.errors = r.response.data;
            });
        },
        isSelected(address) {
            if (this.selected) {
                return this.selected === address.id
            } else {
                return address.default;
            }
        }
    },
}
</script>

<template>

    <div class="mb-3">
        <label for="address">{{ $t('delivery.address.select') }}</label>

        <div class="row">
            <div class="col-12 col-lg-4 my-3" v-for="address of addresses">
                <div class="border border-primary p-2 rounded-3" @click="select(address.id)"
                     :class="{'bg-secondary': isSelected(address)}">
                    <input type="hidden" name="address_id" :value="address.id" v-if="isSelected(address)">
                    <div class="lead">
                        <i class="bi bi-bookmark-star" v-if="address.default"></i>
                        {{ address.name }}
                    </div>
                    <p>
                        {{ address.city }}, {{ address.street }}
                    </p>
                    <small>{{ address.comment }}</small>
                </div>
            </div>
            <div class="col-12 col-lg-4 my-3">
                <div class="border border-primary p-2 rounded-3 d-flex align-items-center justify-content-center h-100">
                    <button aria-label="add-new-user-address" type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#new-user-address">
                        {{ $t('delivery.add_new_user_address') }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!addresses || !addresses.length">
            {{ $t('delivery.user_address.none') }}
        </div>
    </div>


    <div class="modal fade" id="new-user-address" tabindex="-1" aria-labelledby="new-user-addressLabel"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="new-user-addressLabel">{{ $t('delivery.new_address.title') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3" id="new-address-name">
                        <label>{{ $t('delivery.address.name') }}</label>
                        <input type="text" class="form-control" name="address_name" v-model="form.name">
                    </div>

                    <div class="mb-3" id="new-city">
                        <label for="city">{{ $t('delivery.city') }}</label>
                        <select class="form-select" aria-label="Default select example" name="city_id"
                                v-model="form.city">
                            <option v-for="city of cities"
                                    :value="city.id">{{ city.title }}
                            </option>
                        </select>
                    </div>

                    <div class="mb-3" id="new-address">
                        <label for="address">{{ $t('delivery.address') }}</label>

                        <input type="text" name="street" class="form-control" :placeholder="$t('delivery.address')"
                               v-model="form.address">
                    </div>

                    <div class="mb-3">
                        <label for="address">{{ $t('delivery.comment') }}</label>
                        <textarea name="comment" id="comment" cols="30" rows="10" class="form-control"
                                  :placeholder="$t('delivery.comment')" v-model="form.comment"></textarea>
                    </div>

                    <div class="mb-3">
                        <label for="address">{{ $t('delivery.default') }}</label>
                        <input type="checkbox" class="form-check" v-model="form.default">
                    </div>

                    <ul class="list-unstyled">
                        <li v-for="error of errors">{{ error[0] }}</li>
                    </ul>

                </div>
                <div class="modal-footer">
                    <button aria-label="close" type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="clear()">
                        {{ $t('delivery.new_address.close') }}
                    </button>
                    <button aria-label="save" type="button" class="btn btn-primary" @click="save()">
                        {{ $t('delivery.new_address.save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.user-address {
    border: 1px solid var(--bs-primary);
}
</style>
