<template>
    <div class="mb-3">
        <label class="form-label" v-if="config.label.length">{{ config.label }}</label>
        <textarea class="form-control" @keyup="keyUp($event.target.value)" v-bind="config">{{config.value}}</textarea>
    </div>
</template>

<script>
export default {
    name: "FText",
    emits: ['update-value'],
    props: ['options', 'name'],
    data() {
        return {
            config: {
                label: '',
                required: false,
                name: 'input',
                placeholder: '',
                autocomplete: 'off',
                type: 'text',
            }
        }
    }, mounted() {
        this.config = Object.assign({}, this.config, this.options);
    }, methods: {
        keyUp(value) {
            this.config.value = value;
            this.$emit('update-value', value);
        }
    }
}
</script>

<style scoped>

</style>
