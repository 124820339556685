<script>
import CartButton from "./CartButton.vue";

export default {
    name: "BuyButton",
    components: {CartButton},
    props: {
        product: {
            type: Object,
            required: true
        },
        checkoutUrl: {
            type: String
        }
    },
    data() {
        return {
            hasSale: false,
            saleType: '%',
            saleAmount: 0,
        }
    },
    mounted() {
        this.hasSale = this.product.initial_price !== this.product.price && this.product.sale;
        if (this.product.hasOwnProperty('sale') && this.product.sale) {
            this.saleType = this.product.sale.type === 'PERCENT' ? '%' : '₾';
            this.saleAmount = this.product.sale.type === 'FIXED' ? this.product.initial_price - this.product.price : this.product.sale.amount;
        }
        if(this.saleAmount < 0) {
            this.saleAmount *= -1;
        }
    },
    methods: {
        buy() {
            this.$refs.cartButton.addToCart(() => {
                window.location.href = this.checkoutUrl;
            });
        }
    }
}
</script>

<template>
    <div class="price-box d-flex gap-2 rounded-3 p-2">
        <div class="price p-2 rounded-3 fs-4 d-flex align-items-center justify-content-center d-flex flex-column">
            <div>
                {{ product.price }}₾
            </div>
            <span v-if="hasSale" class="sale-type rounded-3 bg-danger text-black p-1">
                -{{ saleAmount }} {{ saleType }}
            </span>
            <div v-if="hasSale" class="old-price text-grey ">
                {{ product.initial_price }}₾
            </div>
        </div>
        <div class="d-flex flex-column gap-2 w-100">
            <button class="btn btn-primary" @click="buy">ყიდვა</button>
            <cart-button ref="cartButton" :qty="product.qty" :product="product.slug" type="long"></cart-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.price-box {
    background: #D9D9D9;

    .price {
        background: #EBF2EA;
        position: relative;

        .old-price {
            text-decoration: line-through;
            font-size: 14px;
        }

        .sale-type {
            position: absolute;
            top: -10%;
            right: -5%;
            font-size: 12px;
        }
    }
}
</style>
