<template>
    <template v-for="item in items">
        <div class="accordion" :class="{'ms-4':!first}" :id="'accordion-'+item.id"
             v-if="item.items && item.items.length">
            <div class="accordion-item">
                <div class="accordion-header d-flex ps-1">
                    <div class="w-100">
                        <f-checkbox v-on:updateCheckbox="checkUpdate($event, item)"
                                    :options="{name:inputName, label: item.name, value:item.id, checked: item.checked}"></f-checkbox>
                    </div>
                    <button class="accordion-button shadow-none bg-transparent px-2 py-1" type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#nested-'+item.id" aria-expanded="true" aria-controls="collapseOne">
                    </button>
                </div>
                <div :id="'nested-'+item.id" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                     :data-bs-parent="'#accordion-'+item.id">
                    <div class="accordion-body p-0">
                        <f-nested v-if="item.items && item.items.length" :selected="selected" :items="item.items"
                                  @update-list="updateList(item, $event)"
                                  :inputName="inputName"></f-nested>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="ms-4 border-start border-light">
            <f-checkbox v-on:updateCheckbox="checkUpdate($event, item)"
                        :options="{name:inputName, label: item.name, value:item.id, checked: item.checked}"></f-checkbox>
        </div>
    </template>

</template>

<script>
import FCheckbox from "./FCheckbox";

export default {
    name: "FNested",
    components: {FCheckbox},
    emits: ['updateList'],
    props: {
        items: {
            type: Array,
            default: [],
        },
        inputName: {
            type: String,
            default: 'categories[]'
        },
        selected: {
            type: Array,
            default: []
        },
        first: {
            type: Boolean,
            default: false
        }
    },
    beforeMount() {
        if (this.selected.length) {
            this.items.map(item => {
                item.checked = this.selected.indexOf(item.id) > -1;
                return item;
            })
        }
    },
    methods: {
        updateList(id, list) {
            this.items.map(item => {
                if (item.id === id)
                    item.items = list;
                return item;
            })
            this.$emit('updateList', this.items);
        },
        checkUpdate(check, item) {
            let list = this.items.map(i => {
                i.checked = item.id === i.id ? check : i.checked ?? false;
                return i;
            })
            this.$emit('updateList', list);
        }
    }
}
</script>

<style scoped>

</style>
