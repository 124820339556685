<script>
import {cart, cartAddOrUpdate, cartList, cartRemoveItem} from "../../utils";
import CartQty from "./CartQty.vue";
import CartRemoveItem from "./CartRemoveItem.vue";

export default {
    name: 'CartModal',
    props: ['checkoutUrl'],
    components: {CartRemoveItem, CartQty},
    data() {
        return {
            updateCycle: null,
            lastItem: '',
        }
    },
    computed: {
        cart() {
            return cart
        },
        totalAmount() {
            let total = 0;
            for (let item of cart.items) {
                total += item.qty * item.amount
            }
            return total.toFixed(2);
        }
    },
    mounted() {
        cart.items = [];
        this.updateCartList();
    },
    methods: {
        updateCartList() {
            cartList().then(response => {
                cart.items = response.data;
            });
        },
        removeItem(accept, item) {
            if (accept) {
                cartRemoveItem(item.slug).then(() => {
                    this.$toast.success(this.$t('cart.item.removed'))
                    this.updateCartList();
                }).catch(e => {
                    this.$toast.error(e.message)
                })
            }
        },
        updateQty(item, qty) {
            if (this.updateCycle && item.slug === this.lastItem) {
                clearTimeout(this.updateCycle);
            }
            this.lastItem = item.slug;
            item.qty = qty;

            this.updateCycle = setTimeout(() => {
                cartAddOrUpdate(item).then(() => {
                    this.$toast.success(this.$t('cart.item.update'));
                }).catch(e => {
                    this.$toast.error(e.message)
                });
            }, 500);
        }
    }
}
</script>

<template>
    <div class="modal fade modal-xl" id="cart-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">ჩემი კალათა</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive" v-if="cart.items.length">
                        <table class="table align-middle table-striped">
                            <tbody>
                            <tr v-for="item in cart.items" :key="item.slug">
                                <td style="min-width: 100px;">
                                    <img :src="item.image" :alt="item.slug" height="96" class="mw-100 object-fit-cover">
                                </td>
                                <td class="w-70">
                                    <a :href="item.view" class="btn btn-link">
                                        {{ item.title }}
                                    </a>
                                </td>
                                <td class="w-5">
                                    {{ item.amount.toFixed(2) }}₾
                                </td>
                                <td class="w-5">
                                    <cart-qty :qty="item.qty" :max-qty="item.maxQty" @update="updateQty(item, $event)"/>
                                </td>
                                <td class="w-5">
                                    {{ (item.qty * item.amount).toFixed(2) }}₾
                                </td>
                                <td class="w-10 text-end">
                                    <cart-remove-item @remove="removeItem($event, item)"></cart-remove-item>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colspan="4"></td>
                                <td class="fw-bold fs-5">{{ totalAmount }}₾</td>
                                <td></td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div v-else>
                        <h4 class="text-danger">
                            კალათა ცარიელია
                        </h4>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button aria-label="close" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        {{ $t('cart.modal.close') }}
                    </button>
                    <a :href="checkoutUrl" type="button" class="btn btn-primary"
                       v-if="cart.items.length">{{ $t('cart.modal.buy') }}</a>
                    <button aria-label="buy" type="button" class="btn btn-primary disabled"
                            v-else>{{ $t('cart.modal.buy') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
