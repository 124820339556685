<script>

export default {
    name: "CartRemoveItem",
    emits: ['remove'],
    data() {
        return {
            showButton: true
        }
    }
}
</script>

<template>
    <div class="position-relative">
        <div v-if="!showButton" class="position-absolute end-0 text-end" style="width: 120px;">
            <button aria-label="remove-item-decline" type="button" class="btn btn-secondary"
                    @click="showButton = true; $emit('remove', false)">
                <i class="bi bi-x-circle"></i>
            </button>
            <button aria-label="remove-item-accept" type="button" class="btn btn-danger ms-2" @click="$emit('remove', true)">
                <i class="bi bi-check-circle"></i>
            </button>
        </div>
        <button aria-label="remove-item" class="btn btn-danger" @click="showButton = false">
            <i class="bi-trash"></i>
        </button>
    </div>
</template>

<style scoped>

</style>
